.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.App-link {
	color: #61dafb;
}

.pageTitle::first-letter {
	text-transform: uppercase;
}
