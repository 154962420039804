@font-face {
	font-family: 'handwrite';
	src: url('../public/assets/fonts/handwrite.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

html,
body {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

body {
	font-family:
		'handwrite',
		Roboto,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
